import {DataSet} from '../../../shared/models/DataSet';
import {Injectable} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BaseApiService} from "../../../shared/services/base-api.service";
import {Document} from "../../../shared/models/Document";

@Injectable({
    providedIn: 'root'
})
export class DownloadsService extends BaseApiService<Document> {

    override endPoint = environment.restApiHost + 'v1/downloads/'

    public listAll(activeOnly = true): Observable<DataSet<Document>> {
        const queryParams = new HttpParams().append('page', '0')
            .append('search', JSON.stringify(activeOnly ? {active: 1} : {}))
            .append('limit', '-1')
            .append('orderBy', 'position')
            .append('ts', new Date().getTime().toString()); // prevent caching in IE
        return this.list(queryParams);
    }

    public saveDownloadsData(downloadsPositioning: Array<Document>) {
        return this.http.post(this.docsEndPoint, downloadsPositioning).pipe(
            finalize(() => {
                this.dataChangedEmit()
            })
        )
    }


}
